/* EDITOR ENTRIES */

.editor-data-entries-wrapper {
  width: 100%;
  height: 55vh;
  overflow-y: auto;
}

.editor-data-entries {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.editor-data-entries-wrapper.single-data {
  height: 17vh;
}

.editor-data-entry {
  display: flex;
  flex-direction: column;
  height: 16vh;
  width: 100%;
  gap: 5%;
}

.editor-data-entry-header {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.editor-data-entry-title {
  padding-left: 1%;
  height: 100%;
  width: 50%;
  background-color: #e2dfdf;
  display: flex;
  align-items: center;
  color: #4e4d4d;
}

.editor-data-entry-header > img {
  width: 2%;
  height: 30%;
  margin-left: 1%;
}

.editor-data-entry-textarea {
  width: 100%;
  height: 80%;
  outline: none;
  padding: 1%;
  font-size: 18px;
  resize: none;
  background-color: #ebe8e8;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  pointer-events: auto;
}

.editor-data-entry-textarea.not-active {
  pointer-events: none;
}

.editor-data-entry-textarea:focus {
  background-color: #fff;
  border: 1px solid #111;
}

.editor-data-entry-textarea.empty {
  border: 1px solid red;
}

@media (orientation: landscape) {
  .editor-data-entries-wrapper {
    height: auto;
    overflow-y: hidden;
  }

  .editor-data-entry {
    height: 35vh;
  }

  .editor-data-entry-title {
    width: auto;
    padding: 0 1%;
  }

  .editor-data-entry-header > img {
    height: 35%;
    width: 1.5%;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
