.custom-pdf-wrapper {
    padding: 10px;
    background-color: white;
}

.tg {
    width: 100%;
}

.custom-pdf-header-logo {
    background-color: white;
}

.custom-pdf-header-title {
    font-size: 6px;
    font-weight: bold;
    text-align: start;
}

.custom-pdf-header-title-place {
    background-color: white;
    padding: 0;
    margin: 0;
    text-align: start;
    padding: 0 2px;
}

.custom-pdf-header-value {
    font-size: 6px;
}

.custom-pdf-header-report {
    background-color: white;
}

.custom-pdf-header-report>span {
    font-weight: bold;
}

.custom-pdf-content {}

.tg-item {
    width: 100%;
    margin: 5px 0;
}

.tg-item span {
    font-size: 8px;
    padding: 0 2px;
    margin: 0;
}

.tf-item>td {
    margin: 0;
}

.item-fields {
    margin-top: 10px;
    margin-bottom: 10px;
}

.item-field {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.field-name {
    font-weight: bold;
}

.field-value {
    flex-grow: 1;
    text-align: left;
}