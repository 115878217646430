.wrapper-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.wrapper-header {
  display: flex;
  /* gap: 2%; */
  /* height: 14%; */
  justify-content: space-between;
  padding: 0% 2%;
  width: 100%;
  margin-top: 1%;
  height: 23vw;
}

.wrapper-button {
  aspect-ratio: 1/1;
  width: 23vw;
}
.wrapper-content {
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: column;
  gap: 2%;
  padding: 2%;
  overflow-y: scroll;
}

.correction-header {
  height: 7vh;
}

.one-link {
  display: flex;
  height: 7vh;
  width: 100%;
}

.wrapper-my-link-button {
  width: calc(100% - 7vh);
  justify-content: center;
  align-items: center;
}
.link-span-inner {
  width: 80%;
  padding: 0 5%;
  font-size: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wrapper-link-cancel {
  height: 100%;
  width: auto;
  aspect-ratio: 1/1;
}

.wrapper-input-link {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 96%;
  height: 7vh;
}
.wrapper-input-link > input {
  width: calc(100% - 7vh);
  font-size: 20px;
  margin: 0.22%;
}

.input-name-link {
  width: 96%;
  position: absolute;
  height: 7vh;
  bottom: 12%;
  font-size: 20px;
}

.wrapper-link-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.wrapper-link-search-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.wrapper-open-share {
  display: flex;
  position: absolute;
  bottom: 2.3%;
  width: 100%;
  height: 12vh;
  gap: 2%;
  padding: 2%;
  justify-content: space-between;

}
.wrapper-link-share-button {
  height: 100%;
    aspect-ratio: 1 / 1;
    width: auto;
}

.wrapper-search-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 2%;
}
.wrapper-search-header {
  display: flex;
  height: 10%;
  width: 100%;
}
.wrapper-search-header > input {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 700;
}
.wrapper-search-button {
  height: 100%;
  aspect-ratio: 1/1;
}

.wrapper-search-body {
  width: 100%;
  height: 90%;
}

.image-dots {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-dots > img {
  object-fit: contain;
}

.visible-img {
  height: 60% !important;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
