.language-flag-new {
  height: 55%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.language-flag-new-landscape {
  height: 100%;
  width: 70%;
  display: flex;
  align-items: center;

  flex-direction: column;
}

.language-flag-halfcircle {
  height: 100%;
  aspect-ratio: 1/2;
  position: relative;
}

.language-flag-halfcircle-landscape {
  height: 20%;
  aspect-ratio: 1/2;
  position: relative;
  width: 80%;
}

.language-circle-flag {
  position: absolute;
  height: 15%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px solid #111;
}

.language-circle-flag-landscape {
  position: absolute;
  height: 30%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px solid #111;
}

.language-circle-flag-landscape > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.language-circle-flag > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.language-flag-center {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.language-flag-center-landscape {
  height: 40%;
  display: flex;
  justify-content: space-between;
}

.language-flag-center-select {
  height: 100%;

  display: flex;
}

.language-flag-center-select-flag {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-flag-center-row {
  display: flex;
  align-items: center;
  height: 15%;
  justify-content: space-between;
}

.language-flag-center-row-landscape {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.language-center-item {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px solid #111;
}

.language-center-item > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.language-center-item-landscape {
  height: 15%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px solid #111;
}

.language-center-item-landscape > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
