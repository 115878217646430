/* EDITOR LIST ITEMS */

.editor-list-items-wrapper {
  width: 100%;
  overflow-y: auto;
}

.editor-list-items {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  width: 100%;
}

.editor-list-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.editor-list-item {
  display: flex;
  background-color: #ebe8e8;
  flex-direction: column;
  gap: 2%;
  height: 10vh;
  width: 100%;
  padding: 1%;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.editor-list-item-header {
  width: 100%;
  height: 30%;
  display: flex;
}
.comment-active {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 31vh;
  gap: 1vh;
}
.comment-active > textarea {
  height: 19vh;
}

.comment-active-buttons {
  display: flex;
  height: 10vh;
  width: 100%;
}
.comment-list-item-button {
  width: 20%;
  height: 100%;
}
.comment-list-item-button:last-child {
  width: 40%;
}

.editor-list-item-header > .number,
.editor-list-item-header > .time,
.editor-list-item-header > .name {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editor-list-item-header > .name {
  width: 70%;
}

.editor-list-item-header > .time {
  justify-content: flex-end;
  font-size: 14px;
}

.editor-list-item-bottom {
  height: 60%;
  width: 100%;
  display: flex;
}

.editor-list-item-bottom > .title {
  width: 85%;
  height: 100%;
  text-align: start;
  font-weight: 600;
}

.editor-list-item-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 15%;
  align-items: flex-end;
  font-size: 14px;
  gap: 20%;
  justify-content: space-between;
}

.editor-list-item-right > span {
  height: 40%;
}

.editor-list-item-right > .comments {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
  height: 40%;
}

.editor-list-item-right > .comments > img {
  height: 80%;
  width: 60%;
  object-fit: contain;
}

/* LIST ITEM DETAILS */

.editor-list-item-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
}

.editor-list-item-article {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  text-align: left;
  padding: 2%;
}

.editor-list-item-title {
  font-size: 16px;
  font-weight: 500;
  color: gray;
  text-align: left;
}

/* LIST ITEM BUTTONS */

.editor-list-item-buttons {
  width: 100%;
  height: 10vh;
  display: flex;
  gap: 4%;
  justify-content: center;
}

.editor-list-item-btn {
  width: 24%;
  height: 100%;
  position: relative;
}

.editor-list-item-btn.comments {
  width: 48%;
}

.comment-list-item-btn {
  width: 20%;
  height: 10vh;
}

/* LIST ITEM COMMENTS */

.editor-list-item-comments {
  width: 80%;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.editor-list-item-comments > span {
  font-size: 2vh;
  font-weight: 500;
  color: gray;
}

.editor-list-item-comments-main-wrapper {
  max-height: 33vh;
  width: 100%;
  overflow-y: auto;
}

.editor-list-item-comments-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editor-comment {
  width: 100%;
  display: flex;
}

.editor-date-time {
  font-size: 30px;
  letter-spacing: 1px;
  font-family: "DS-Digital", sans-serif;
}

.editor-comment > span {
  font-weight: 600;
}

.editor-comment-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 2%;
  gap: 1vh;
}

.editor-comment-main > .user {
  font-weight: 600;
}

.editor-comment-main > .text {
  text-align: left;
}

.editor-comment-main > .buttons {
  display: flex;
  gap: 2vw;
  height: 8vh;
}

.buttons-icon {
  height: 100%;
  width: 25%;
}

.comments-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.wrapper-users-container {
  position: absolute;
  top: unset;
  bottom: 110%;
  left: 0;
  height: 40vh;
  width: 100%;
  background-color: #c4bebe;
  overflow-y: auto;
  z-index: 100000;
}

.wrapper-users-container.bottom {
  bottom: unset;
  top: 100%;
}

.user-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}

.users-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

@media (orientation: landscape) {
  .editor-list-item-wrapper {
    flex-direction: row;
    height: 12vh;
    justify-content: space-between;
  }

  .editor-list-item,
  .editor-list-item-buttons {
    height: 100%;
    width: 48%;
    padding: 11px 0;
  }
}
@media (orientation: portrait) {
  .editor-list-item-buttons {
    gap: 0;
  }

  .editor-list-item-buttons.fixed {
    position: absolute;
    bottom: 0;
    z-index: 20;
    width: 100%;
  }

  .comment-active-buttons.fixed {
    position: absolute;
    bottom: 0;
    z-index: 30;
    width: 100%;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
