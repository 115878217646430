.wrapper-settings-style-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2%;
  overflow-y: auto;
}

.column-wrapper-settings-style-screen {
  display: flex;
  width: 100%;
  padding: 2%;
  gap: 2%;
  flex-wrap: wrap;
}

.settings-button-screen-style {
  display: flex;
  flex-direction: column;
  width: 23%;
  aspect-ratio: 1/1;
}

.screen-hight-second {
  height: 70%;
}

.screen-hight-first {
  height: 20%;
}

.screen-background-red {
  background-color: red;
}

.screen-background-green {
  background-color: green;
}

.style-screen-span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 5%;
}

.settings-button-style-screen-image {
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: contain;
  justify-content: center;
  align-items: center;
}

.settings-button-style-screen-image > img {
  object-fit: contain;
  height: 50%;
}

.settings-button-style-color {
  width: 60%;
  aspect-ratio: 1/1;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
