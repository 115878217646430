/*
  250px - 400px da bude funkcionalno
  400px - 700px da bude funkcionalno
  700px - landscape
*/

.filter-archive-landscape {
  position: absolute;
  top: 1%;
  width: 97%;
  height: 35%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  background-color: gray;
  border-radius: 5px;
  z-index: 10;
  align-self: center;
  justify-self: center;
}

.main-landscape {
  display: flex;
  height: 100%;
  gap: 6%;
}

.left-container-landscape {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  gap: 2%;
}

.left-top-container-landscape {
  display: flex;
  height: 44%;
  gap: 3%;
}

.wrap-dates-landscape {
  display: flex;
  flex-direction: column;
  gap: 10%;
  height: 100%;
  width: 49%;
}

.date-landscape {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75%;
}
.date-landscape > span {
  padding-left: 10%;
  font-family: "DS-Digital", sans-serif;
  font-size: 35px;
}

.end-date-calendar-img-landscape {
  opacity: 0.2;
}

.end-date-calendar-landscape > span {
  align-self: center;
  justify-self: center;
}

.vertical-button-row-1-landscape {
  display: flex;
  height: 52%;
  width: 70%;
  gap: 4%;
  align-self: end;
}

.first-group-landscape {
  display: flex;
  width: 100%;
}

.button-message-landscape {
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  align-self: flex-end;
  justify-self: flex-end;
  position: relative;
}

.file-message-landscape {
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  align-self: flex-end;
  justify-self: flex-end;
  position: relative;
}

.link-message-landscape {
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  align-self: flex-end;
  justify-self: flex-end;
  position: relative;
}

.image-message-landscape {
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  align-self: flex-end;
  justify-self: flex-end;
  position: relative;
}

.dot-landscape {
  height: 35%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: white;
  border-style: solid;
  position: absolute;
  right: 5%;
  top: -18%;
}

/*levo dole*/
.left-bottom-container-landscape {
  display: flex;
  height: 54%;
  gap: 5%;
}

.horizontal-button-row-1-landscape {
  display: flex;
  align-items: center;
  gap: 7%;
  height: 100%;
  width: 49%;
}

.chart-landscape {
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  align-self: center;
  justify-self: center;
}

.profile-landscape {
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  align-self: center;
  justify-self: center;
}

.vertical-button-row-3-landscape {
  display: flex;
  flex-direction: column;
  gap: 2%;
  height: 100%;
  width: 25%;
}

.colors-landscape {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10%;
}

.row-flex-landscape {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  gap: 4%;
}

.info-text {
  display: flex;
  width: 100%;
}

.info-text > span {
  font-size: 16px;
}

.info-color-landscape {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 2.5vh;
  height: 2.5vh;
  aspect-ratio: 1/1;
}

.gray {
  background-color: rgb(94, 88, 88);
}

.red {
  background-color: rgb(255, 0, 0);
}

.green {
  background-color: rgb(17, 228, 27);
}

.right-container-landscape {
  display: flex;
  flex-direction: column;
  width: 48%;
  justify-content: flex-end;
}

.search-container-landscape {
  display: flex;
  flex-direction: column;
  height: 40%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.search-landscape {
  display: flex;
  flex-direction: end;
  height: 100%;
  width: 100%;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
