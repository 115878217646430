.wrapper-send-registration {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 5%;
  align-items: center;
  justify-content: center;
  background-color: rgb(240, 240, 240);
}

.wrapper-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5%;
  background-color: white;
  height: 45%;
  width: 80%;
}
.wrapper-send-registration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10% 0;
}
.wrapper-send-to {
  display: flex;
  flex-direction: column;
}

.wrapper-copy-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgb(200, 200, 200);
  height: 35%;
  width: 80%;
  padding: 5%;
}
.copy-text-button {
  display: flex;
  height: 10%;
  width: 80%;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
