.wrapper-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.wrapper-info-profile > span {
  color: black;
  font-size: 20px;
}
.profile-picture {
  height: 60%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-picture > img {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: gray;
}

.profile-picture > span {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: gray;
  font-size: 50px;
  font-weight: 700;
}

.wrapper-input {
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: flex-end;
}
.wrapper-info-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
  height: 50%;
  width: 100%;
}
.wrapper-input > input {
  height: 15%;
  width: 80%;
}
.wrapper-input-button {
  height: 15%;
  width: 20%;
}
