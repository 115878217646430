.languages-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

.languages-header-container {
  display: flex;
  justify-content: space-evenly;
  z-index: 1000;
}

.languages-header button {
  background: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  height: 100%;
  object-fit: contain;
}

#root
  > div
  > div.languages-header-container
  > div
  > div:nth-child(1)
  > button
  > div {
  display: flex;
  justify-content: start;
  padding-left: 5%;
}

#root
  > div
  > div.languages-header-container
  > div
  > div:nth-child(2)
  > button
  > div {
  display: flex;
  justify-content: end;
  padding-right: 5%;
}

.languages-header img {
  width: 60px;
}

.languages-header-button {
  width: 50%;
  height: 100%;
  background-color: transparent;
}

.language-header-bar {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.visible-img {
  height: 60% !important;
}
