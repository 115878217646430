.language-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  margin: 15px 0;
  height: 5%;
}

.language-section {
  margin-bottom: 0;
}

.language-section > button {
  height: 100%;
}
.language-list {
  display: flex;
  flex-direction: column;
  height: 90%;
}
.language-section input {
  border-top: var(--border-dark) 3px solid;
  border-bottom: var(--border-light) 3px solid;
  border-left: var(--border-light) 3px solid;
  border-right: var(--border-dark) 3px solid;
  height: 100%;
  font-size: 1.6em;
}
.language-text {
  width: 80%;
  height: 100%;
}

.edit-button {
  height: 100%;
  aspect-ratio: 1/1;
}

.flag-button {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  aspect-ratio: 1/1;
}

.visible-img {
  height: 60% !important;
}
