.comment-active-image {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1vh;
}

.comment-active-image > textarea {
  height: 19vh;
}

.not-active {
  pointer-events: none;
  opacity: 0.2;
}
.comment-list-item-button {
  pointer-events: auto;
}

.visible-img {
  height: 60% !important;
}
