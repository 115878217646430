.msg-forward {
  position: absolute;

  width: 99.9vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  padding-left: 5%;
  background-color: rgb(153, 153, 153, 0.8);
}

.msg-forward.mini {
  left: 0;
}

.msg-forward-wrapper {
  height: 87%;
  width: 95%;
  display: flex;
  flex-direction: column;
  padding: 2%;
  background-color: #e6e3e3;
  border-radius: 5px;
  align-items: center;
  gap: 2%;
}

.msg-forward-header {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msg-cancel {
  height: 80%;
  aspect-ratio: 1 / 1;
}

.msg-forward-chats {
  height: 80%;
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  gap: 2%;
}

.msg-user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5%;
  background-color: #007bff;
  color: #fff;
  padding: 2%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

.msg-user.selected {
  background-color: #28a745;
}

.msg-forward-btn {
  height: 10%;
  width: 40%;
}
.forward-message-text {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
