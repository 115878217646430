.wraper-filter-category {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -40%;
  left: -19vw;
  height: 86%;
  width: 88vw;
  background-color: #c4bebe;
  overflow-y: auto;
}

.wraper-viewer-category {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* top: -40%; */
  /* left: -20vw; */
  height: 86%;
  width: 96%;
  background-color: #c4bebe;
  overflow-y: auto;
}

.category-filter-modal-item-button {
  width: 100%;
  height: 7vh;
}

.category-filter-modal-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 5%;
}

.category-filter-modal-item-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.category-filter-modal-item-letter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
}
.category-filter-modal-item-name {
  font-size: 15px;
  font-weight: 700;
}
.category-filter-modal-item-color-letter {
  width: 50%;
  aspect-ratio: 1/1;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
