* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.profile-container {
  height: 100vh;
  width: 100%;
  padding: 5%;
  background-color: #f2f2f2ff;
}

/* ProfileLogin.jsx */

.profile-login {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  align-items: center;
  justify-content: center;
}

.profile-login-img {
  height: 40%;
  width: 200px;
  cursor: pointer;
  object-fit: contain;
}

.profile-login-img > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.profile-login-info {
  height: 40%;
  width: 80%;
  cursor: pointer;
  user-select: none;
  font-size: 10px;
  overflow-y: auto;
  background-color: white;
  padding: 2%;
}

.profile-login-info > img {
  width: 8%;
  aspect-ratio: 1/1;
}

.profile-login-img-container {
  height: 100%;
  width: 200px;
  position: relative;
}

.profile-login-img-container > .hand {
  position: absolute;
  height: 45%;
  aspect-ratio: 1 / 1.2;
  z-index: 10;
}

.profile-login-img-container > .image,
.profile-login-img-container > .user-image {
  position: absolute;
  height: 65%;
  aspect-ratio: 1/1;
  left: 0;
  top: 0;
}

.profile-login-img-container > .user-image,
.profile-login-img-container > .image {
  border: 5px solid #111;
  border-radius: 50%;
}

.profile-login-form {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-login-form-entry {
  flex: 1;
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  font-size: 20px;
  gap: 2%;
}

.profile-login-form-entry > img {
  height: 40%;
  object-fit: contain;
}

.profile-login-form-entry > input {
  width: 100%;
  padding: 3% 2%;
  height: 40%;
  text-align: start;
  outline: none;
  border: none;
  font-size: 20px;
}

.profile-login-form-entry > span {
  width: 100%;
  padding: 3% 5%;
  text-align: start;
}

.profile-login-btn {
  height: 10%;
  width: 70%;
}

.profile-login-btn-img {
  height: 70%;
  width: 20%;
}

input[type="file"] {
  display: none;
}

/* ProfileSelect.jsx */

.profile-select-items {
  height: 65%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15%;
}

.profile-select-item {
  flex: 1;
  position: relative;
  width: 100%;
}

.profile-select-item-bottom {
  height: 10%;
  width: 100%;
  position: relative;
}

.profile-select-item > input,
.profile-select-item-bottom > input {
  height: 100%;
  width: 100%;
  padding-left: 2%;
  outline: none;
  border: none;
  font-size: 20px;
}

.profile-select-item-btn {
  position: absolute;
  right: 0;
  top: 0;
  aspect-ratio: 1/1;
  height: 100%;
}

.profile-select-item-img {
  height: 40%;
  width: 40%;
  transform: rotate(-90deg);
}

.profile-select-buttons {
  width: 100%;
  display: flex;
  height: 10%;
  justify-content: space-between;
  gap: 20%;
}

.profile-select-buttons > img,
.cv-image-container {
  flex: 1;
  aspect-ratio: 1/1;
  cursor: pointer;
}

.cv-image {
  height: 100%;
  width: 100%;
}

/* SelectBox.jsx */

.select-box {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 30vh;
  border: 1px solid #dbd8d8;
  background-color: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 30px;
}

.select-box-item {
  width: 100%;
  padding: 2% 5%;
  border-bottom: 1px solid #dbd8d8;
  cursor: pointer;
  font-size: 30px;
}

.select-box-item.selected {
  color: #fff;
  background-color: gray;
}

/* ProfileCheckout.jsx */

.profile-login-img-container > .picture-image {
  height: 100%;
  width: 100%;
}
.visible-img {
  height: 60% !important;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
