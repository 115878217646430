.left-side-message {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  padding: 5%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f9f9f9;
  gap: 5px;
  color: #fff;
  user-select: none;
  z-index: 20;
}
