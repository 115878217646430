.calendar-wrapper {
  position: absolute;
  top: 15%;
  width: 90%;
  height: 85%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  background-color: #ebe7e7;
  border-radius: 5px;
  font-family: "DS-Digital", sans-serif;
  z-index: 1000;
}

.calendar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.calendar-header {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
}

.calendar-header-left,
.calendar-header-right {
  height: 90%;
  aspect-ratio: 1 / 1;
  cursor: pointer;
}

.calendar-header-date {
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;
  padding-bottom: 1%;
}

.calendar-main-wrapper {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 2% 0;
  align-items: center;
}

.calendar-main {
  width: 95%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(7, calc(100% / 7));
  grid-template-rows: repeat(7, calc(100% / 7));
  justify-content: center;
  align-items: center;
  border-collapse: collapse;
  padding: 0 2%;
}

.calendar-main-item {
  display: flex;
  justify-content: center;
  border: 1px solid #111;
  height: 100%;
  align-items: center;
  gap: 1vw;
}

.calendar-main-item.active.selected {
  background-color: #13d613;
}

.calendar-main-item.letter {
  font-weight: 700;
  background-color: #ebe9e9;
}

.calendar-main-item.active {
  cursor: pointer;
}

@media (orientation: landscape) {
  .calendar-wrapper {
    left: 1.5%;
    right: unset;
    top: 3%;
    width: 47%;
    height: 93vh;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
