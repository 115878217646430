.wrapper-trust-contact {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}
.trust-contact {
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 35%;
  width: 100%;
}
.trust-contact-seted > img {
  height: 100%;
  object-fit: contain;
}
.trust-contact > input {
  height: 15%;
  width: 100%;
}

.trust-contact > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 15%;
  width: 100%;
  border: 1px solid black;
  color: black;
}

.wrapper-trust-contact-button {
  display: flex;
  width: 100%;
  height: 20%;
  gap: 10%;
}
.trust-contact-button {
  width: 45%;
}
.finish-contact-button {
  width: 90%;
  height: 7%;
}
.trust-contact-button-image {
  display: flex;
  align-self: center;
  justify-self: center;
  width: 80%;
  height: 100%;
}
.wrapper-button-edit {
  height: 100%;
  aspect-ratio: 2/1;
}
.trust-contact-seted {
  height: 15%;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.trust-contact-privilege {
  display: flex;
  width: 100%;
  height: 50%;
}

.visible-img {
  height: 60% !important;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
