/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.single-account {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.single-account-key {
  display: flex;
  width: 100%;
  height: 12%;
  gap: 2%;
}

.single-account-key > * {
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.single-account-key > img {
  border: none;
}

.single-account > img {
  height: 20%;
  aspect-ratio: 1/1;
  margin-left: 5%;
}

.account-container {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%;
}

.account-container > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-container > span:first-child {
  height: 75%;
  width: 100%;
  font-size: 180px;
  font-weight: 700;
}

.account-container > img:first-child {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.account-container > span:nth-child(2) {
  height: 20%;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}

.play-button {
  height: 20%;
  aspect-ratio: 1/1;
  margin-left: 5%;
}
.play-button > img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 730px) {
  .single-account {
    display: flex;
  }

  .single-account-landscape {
    display: none;
  }
}

@media only screen and (min-width: 730px) {
  .single-account-landscape {
    display: flex;
    justify-content: space-between;
    margin-top: 10vh;
  }

  .single-account {
    display: none;
  }

  .sakl {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 45%;
  }

  .play-button {
    margin-top: 5vh;
  }

  .account-container {
    width: 45%;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
