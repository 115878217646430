.user-manual-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 11.11vh);
  position: absolute;
  background-color: white;
  z-index: 500;
}
.user-manual-container {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
}
.user-manual-container > img,
.user-manual-pdf {
  object-fit: contain;
  width: 15%;
  aspect-ratio: 1/1;
}

.user-manual-pdf-img {
  width: 100%;
  aspect-ratio: 1/1;
}

.user-manual-text {
  display: flex;
  width: 100%;
  height: 85%;
  padding: 0 5%;
  overflow-y: auto;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
