.image-document-wrapper {
  position: absolute;
  background-color: #fff;
  top: 0;
  height: 71vh;
  width: 100%;
  z-index: 10;
  border: 1vw solid #c4bebe;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.wraper-document-link {
  width: 100%;
}

.document-link-add {
  display: flex;
  height: 50%;
  gap: 5%;
  width: 100%;
  justify-content: center;
}

.document-add {
  height: 90%;
  aspect-ratio: 2/1;
  pointer-events: auto;
}

.document-add > input {
  display: none;
}

.disable-link {
  opacity: 0.2;
  pointer-events: none;
}

.image-modal-wrapper > input {
  width: 90%;
}

.wrapper-link-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8%;
  height: 30%;
  justify-content: center;
  align-items: center;
}

.wrapper-link-button > input {
  width: 90%;
}

.button-image-document-link {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 5%;
  background-color: #999999ff;
  cursor: pointer;
}

.button-image-document-link > img {
  height: 50%;
  width: 30%;
  object-fit: contain;
}

.download-link {
  height: 10%;
  padding: 1%;
  width: max-content;
  align-self: flex-start;
  margin-bottom: 1%;
  white-space: nowrap;
  text-align: start;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  margin-left: 5%;
  transition: 0.2s ease;
  border-radius: 5px;
}

.download-link > img {
  height: 40%;
  aspect-ratio: 1 / 1;
  margin-right: 1vw;
}

.download-link:hover {
  background-color: #8c8cec;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
