.home-page {
  width: 100%;
  height: 100%;
  padding: 2%;
  display: grid;
  grid-template-columns: repeat(2, 16.5vh); /* Dve kolone sa jednakom širinom */

  align-self: center;
  justify-self: center;
  justify-content: center;
}

.wrapper-item-button {
  display: flex;
  width: 95%;
  aspect-ratio: 1/1;
  align-self: center;
  justify-self: center;
}
.home-page-image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-image > img {
  height: 60%;
  object-fit: contain;
}

.home-email {
  margin-top: 20px;
  font-size: 20px;
}
