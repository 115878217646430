* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  background-color: #f2f2f2;
}

.app {
  width: 100vw;
  display: flex;
  height: 90vh; /* 90vh for web browsers */
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9ff;
}

/* Styles for when the app is running as a PWA (standalone or fullscreen) */
@media all and (display-mode: standalone), all and (display-mode: fullscreen) {
  .app {
    height: 100vh; /* 100vh for installed PWA */
  }
}

html,
body {
  overscroll-behavior-y: contain;
  overflow-y: hidden;
}
body {
  position: fixed;
  background-color: #f9f9f9ff;
}
