.wrapper-pin-unlock-log {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
}
.current-pin-log {
  display: flex;
  width: 100%;
  height: 15%;
  justify-content: center;
  align-items: center;
  gap: 3%;
}
.current-pin-dot-log {
  height: 30%;
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: 50%;
}
.current-pin-dot-log.animated {
  animation: red-dot;
  animation-duration: 1s;
}
.wrapper-pin-choose-element-log {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  height: 100%;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.number-choose-pin-log {
  display: flex;
  aspect-ratio: 1 / 1;
  height: 70%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;
  border: solid 3px black;
  font-size: 3vh;
  cursor: pointer;
  z-index: 200;
  user-select: none;
}

.number-choose-pin-log:nth-child(3n + 2) {
  margin-left: 5%;
  margin-right: 5%;
}
.pin-delete-dot-log,
.pin-ok-dot-log {
  height: 10%;
  width: 25%;
  position: absolute;
  color: #fff;
  bottom: 12%;
  left: 13%;
  cursor: pointer;
}

.pin-ok-dot-log {
  display: flex;
  left: unset;
  right: 13%;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
  font-weight: 600;
}

@keyframes red-dot {
  0% {
    background-color: white;
  }
  25% {
    background-color: red;
  }
  100% {
    background-color: red;
  }
}
