.accounts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 5%;
  padding: 10%;
  max-height: 100vh;
}
.account-list::-webkit-scrollbar {
  display: none;
}

.account-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 5vh;
}

.account {
  width: 100%;
  aspect-ratio: 1/1;
}
.account-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1/1;
  justify-content: space-evenly;
  align-items: center;
}

.account-button > span {
  width: 100%;
  font-size: 40px;
}

.account-button-image {
  aspect-ratio: unset;
  width: 60%;
  height: 70%;
}

.initial {
  font-size: 100px !important;
  font-weight: 600;
}
.wrapper-location {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 5%;
}

.concret-location {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  overflow-y: auto;
  gap: 5%;
}

.logo-account {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-account > img {
  height: 100%;
  object-fit: contain;
}

.name-account {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 100%;
  font-size: 30px;
  font-weight: 700;
}

.location-name {
  display: flex;
  width: 100%;
  height: 30%;
  justify-content: center;
  align-items: center;
}

.location-name-current {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
}

@media only screen and (min-width: 730px) {
  .wrapper-location {
    margin-top: 10vh;
  }

  .wrapper-location button {
    padding: 20px 0;
  }

  .wrapper-location .location-name {
    margin: 10px 0;
  }

  .account-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .account-button span {
    font-size: 30px;
  }

  .account {
    height: 30vw;
    width: 30vw;
  }

  .account img {
    height: 20vw;
    width: 20vw;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
