.image-document-wrapper {
  position: absolute;
  background-color: #fff;
  top: 0;
  height: 71vh;
  width: 100%;
  z-index: 10;
  border: 1vw solid #c4bebe;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.visible-img {
  height: 60% !important;
}
.wraper-document-link {
  width: 100%;
}

.document-add {
  height: 90%;
  aspect-ratio: 2/1;
  pointer-events: auto;
}

.document-add > input {
  display: none;
}

.disable-link {
  opacity: 0.2;
  pointer-events: none;
}

.image-modal-wrapper > input {
  width: 90%;
}

.wrapper-link-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8%;
  height: 25%;
  justify-content: center;
  align-items: center;
}

.wrapper-link-button > input {
  width: 90%;
}

.button-image-document-link {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 5%;
  background-color: #999999ff;
  cursor: pointer;
}

.button-image-document-link > img {
  height: 50%;
  width: 30%;
  object-fit: contain;
}

.download-link:hover {
  background-color: #8c8cec;
}

.wrapper-import-links {
  display: flex;
  width: 100%;
  height: 15%;
  padding: 2% 5%;
  gap: 2%;
}

.import-links-button {
  width: 33%;
  height: 100%;
}

.wrapper-container-link {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35%;
  overflow-y: auto;
  padding: 2% 5%;
  gap: 2%;
}

.wrapper-container-link-add {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40%;
  gap: 5%;
  padding: 2% 5%;
  overflow-y: auto;
}

.wrapper-add-link {
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 45%;
  width: 100%;
}
.wrapper-added-link {
  display: flex;
  gap: 5%;
  height: 30%;
  width: 100%;
}

.download-link-height {
  height: 100%;
  width: 100%;
}

.download-link-height > img {
  height: 100%;
  aspect-ratio: 1 / 1;
  margin-right: 1vw;
}

.wrapper-link {
  display: flex;
  gap: 2%;
  width: 100%;
  height: 50%;
}
.wrapper-name-link {
  height: 45%;
  width: 100%;
}
.add-link-button {
  height: 100%;
  width: 10%;
}
.button-link {
  width: 90%;
  display: flex;
}

.button-send {
  width: 20%;
}

.wrapper-count-name {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 5%;
  padding: 0 5%;
}

.wrapper-count-name > span {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-link-name {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
