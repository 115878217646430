.message-log-out-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(6.4, 6.4, 6.4, 0.5);
  position: absolute;
  top: 0;
}

.message-log-out-container {
  height: 30%;
  width: 90%;
  background-color: white;
  border-radius: 5px;
  position: relative;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 5%;
}

.cancel-message-log-out {
  position: absolute;
  top: 7.5%;
  right: 5%;
  height: 10%;
  aspect-ratio: 1/1;
}

.leave-button-log-out {
  width: 40%;
  height: 30%;
}
.leave-application-log-out {
  text-transform: capitalize;
}
.message-log-out-text {
  text-align: center;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
