.wrapper-settings-default-page {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 5%;
}
.column-wrapper-settings-default-page {
  display: flex;
  height: 100%;
  width: 50%;
  padding: 2%;
  flex-direction: column;
  gap: 2%;
}
.settings-button-default-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 23%;
}
.settings-button-default-page-image {
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: contain;
  justify-content: center;
  align-items: center;
}

.settings-button-default-page-image > img {
  object-fit: contain;
  height: 50%;
}
